import React from 'react';
import { Menu } from 'antd';
import { useTodo } from './todoContext';
import { UserOutlined } from '@ant-design/icons';

const SidebarMenu = () => {
  const { user, auth } = useTodo();

  return (
    <Menu theme="dark" mode="inline">
      <Menu.SubMenu key="1" icon={<UserOutlined />} title={user.displayName}>
        <Menu.Item key="sub1" onClick={() => auth.signOut()}>
          Sign Out
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default SidebarMenu;
