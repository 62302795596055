import React from 'react';
import { Table } from 'antd';
import CreateTodoModal from './CreateTodoModal';
import { useTodo } from './todoContext';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const styles = {
  bordered: true,
  pagination: { position: ['bottomCenter'], defaultPageSize: 5 },
  size: 'default',
  top: 'none',
};

const TodoList = () => {
  const { updateTodo, deleteTodo, getQuery, user } = useTodo();
  const [todos, loading, error] = useCollectionData(getQuery(user.uid), {
    idField: 'id',
  });

  const handleOnChange = async (id, completed) => {
    try {
      await updateTodo(id, completed);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, { completed }) =>
        completed ? (
          <span style={{ textDecoration: 'line-through' }}>{text}</span>
        ) : (
          text
        ),
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      filters: [
        { text: 'Completed', value: true },
        { text: 'Incompleted', value: false },
      ],
      render: (_, { id, completed }) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={completed}
          onChange={(checked) => handleOnChange(id, checked)}
        />
      ),
      onFilter: (value, { completed }) => completed === value,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { id }) => (
        <span>
          <a
            href={null}
            style={{ marginRight: 16 }}
            onClick={async () => {
              try {
                await deleteTodo(id);
              } catch (error) {
                console.error(error);
              }
            }}
          >
            Delete
          </a>
        </span>
      ),
    },
  ];

  return (
    <>
      <CreateTodoModal />
      {error && <p>{error}</p>}
      <Table
        title={() => <h2>My Todos</h2>}
        {...styles}
        columns={columns}
        loading={loading}
        dataSource={
          todos ? todos.map((item) => ({ ...item, key: item.id })) : null
        }
      />
    </>
  );
};

export default TodoList;
