import React, { useState } from 'react';
import firebase from 'firebase/app';
import { useTodo } from './todoContext';
import { Button, Modal, Space } from 'antd';
import { GoogleCircleFilled } from '@ant-design/icons';

const SignIn = () => {
  const { auth } = useTodo();
  const [loading, setLoading] = useState(false);

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    setLoading(true);

    try {
      await auth.signInWithPopup(provider);
    } catch (error) {
      console.log('error', error);

      Modal.error({
        title: 'Login Error',
        content: error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react/button-has-type
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        padding: '4rem',
      }}
    >
      <Space direction="vertical">
        <Button
          icon={<GoogleCircleFilled />}
          loading={loading}
          onClick={signInWithGoogle}
        >
          SignIn with Google
        </Button>
      </Space>
    </div>
  );
};

export default SignIn;
