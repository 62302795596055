import React, { useState } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { useTodo } from './todoContext';

const CreateTodoModal = () => {
  const { createTodo } = useTodo();
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    if (!saving) {
      setVisible(false);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setSaving(true);

    try {
      const { description } = await form.validateFields();

      if (!description) {
        return;
      }

      await createTodo(description);
      form.resetFields();
      setVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        style={{ marginBottom: '0.625rem' }}
        onClick={showModal}
      >
        Create New Todo
      </Button>

      <Modal
        title="Create New Todo"
        visible={visible}
        onOk={handleOk}
        confirmLoading={saving}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          name="form-add-todo"
          initialValues={{ description: '' }}
        >
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTodoModal;
