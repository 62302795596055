import React, { useContext, createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';

firebase.initializeApp({
  apiKey: 'AIzaSyDrscyVHAjBHRLQ7yooKu6fhe1GVPn4l-c',
  authDomain: 'mytodos-web-app.firebaseapp.com',
  projectId: 'mytodos-web-app',
  storageBucket: 'mytodos-web-app.appspot.com',
  messagingSenderId: '816163354629',
  appId: '1:816163354629:web:85ab3250e21330a327446c',
  measurementId: 'G-Y65REGSTK2',
});

firebase.analytics();

const TodoContext = createContext(null);

export function TodoProvider({ children }) {
  const firestore = firebase.firestore();
  const todosCollection = firestore.collection('todos');
  const auth = firebase.auth();
  const [user] = useAuthState(auth);

  const getQuery = (uid) => {
    return todosCollection
      .where('uid', '==', uid)
      .orderBy('createdAt')
      .limit(25);
  };

  const updateTodo = async (id, completed) => {
    try {
      await todosCollection.doc(id).update({ completed });
    } catch (error) {
      console.error(error);
    }
  };

  const createTodo = async (description) => {
    const todo = {
      description,
      completed: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: auth.currentUser.uid,
    };

    try {
      await todosCollection.add(todo);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTodo = async (id) => {
    try {
      await todosCollection.doc(id).delete();
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = {
    firestore,
    auth,
    user,
    createTodo,
    deleteTodo,
    updateTodo,
    getQuery,
  };

  return (
    <TodoContext.Provider value={contextValue}>{children}</TodoContext.Provider>
  );
}

export function useTodo() {
  const context = useContext(TodoContext);

  if (!context) {
    throw new Error(
      'useTodo must be used within a TodoProvider. Wrap a parent component in <TodoProvider> to fix this error.'
    );
  }
  return context;
}
