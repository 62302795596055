import React, { useState } from 'react';
import TodoList from './components/TodoList';
import SidebarMenu from './components/SidebarMenu';
import SignIn from './components/SignIn';
import { useTodo } from './components/todoContext';
import { Layout } from 'antd';
import icon from './img/icon.png';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './App.css';

const { Header, Footer, Sider, Content } = Layout;

const App = () => {
  const { user } = useTodo();
  const [collapsed, setCollapsed] = useState(true);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout>
      {user && (
        <Sider trigger={null} breakpoint="lg" collapsedWidth="0" collapsible collapsed={collapsed}>
          <img src={icon} className="logo" alt="logo" />
          {<SidebarMenu />}
        </Sider>
      )}
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {user &&
            React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: toggle,
              }
            )}
          <h1 style={{ textAlign: 'center' }}>My Todos</h1>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 10px',
            padding: 24,
            minHeight: 460,
          }}
        >
          {user ? <TodoList /> : <SignIn />}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Rolando Coro ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
